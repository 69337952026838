import React, {useState} from 'react'

const Geo = ()=> {
  const [location, setLocation]=useState("")

    function success(position){
     setLocation(+ position.coords.latitude+
position.coords.longitude
      )}

     function error(err) {
         console.log(err.message)

    }

    const options={
      enableHighAccuracy:true,
      maximumAge:3000,
      //timeout:27000,
    };


    const watchId = navigator.geolocation.watchPosition(
success, error, options
    );
   // {watchId}

  return (
    <div>
   User: 
   </div>
  )
  }

export default Geo