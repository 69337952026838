import Google from "./Google";
import Footer from "./Footer";
import Header from "./Header";
import { Col } from "react-bootstrap";


const Blogs = () => {
  return (
    <div className="App">
      <Header />
      <Col style={{ backgroundColor: "black" }}></Col>
      <Google />
      <Footer />
    </div>
  );
};

export default Blogs;
