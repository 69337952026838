  import * as React from "react";
  import ReactDOM from "react-dom/client";
//import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blogs from "./components/Blogs";
import "./bootstrap.min.css"
import { StrictMode } from "react";
import NoPage from "./components/NoPage"
import GeoReact from "./components/GeoReact";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Blogs/>} />
          <Route path="/*" element={<NoPage/>} />
          <Route path="/georeact" element={<GeoReact/>} />
        </Route>
       
      </Routes>  
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    {" "}
    <App />{" "}
  </StrictMode>
);
