import { Link } from "react-router-dom";
import {Col, Row} from "react-bootstrap"
import "./Google.css";
import Footer from "./Footer";


const NoPage = ({ location }) => {
  return (
    <div className="container20">
   <br></br>
   <Col  align="center" >    
<h3 style={{color:"white"}}>SORRY, THERE IS NO PAGE HERE!</h3>
<Link to="/" style={{color:"white"}}>Go Back</Link>




     
    </Col>
    </div>
 )
};

export default NoPage;