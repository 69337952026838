import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Geo from "./Geo";

const GeoReact = () => {
  const [location, setLocation] = useState("");

  function getLocation() {
    navigator.geolocation.getCurrentPosition(showPosition, showError);
  }

  function showPosition(position) {
    setLocation(
      "   Lat: " +
        position.coords.latitude +
        "   Long: " +
        position.coords.longitude 
    );
  }

  const showError = (err) => {
    console.log("err");
    console.log(err.message);
  };

  return (
    <div align="center">
      {location ? (
        <small style={{ color: "black" }}>
          Your coordinates are: {location}{" "}
        </small>
      ) : (
        ""
      )}
      <Button size="sm" variant="dark" onClick={getLocation}>
        See your position
      </Button>
      <Geo />
    </div>
  );
};

export default GeoReact;
